import React from "react"
import LayoutDark from "./shared/layout/layout-dark"
import { Helmet } from "react-helmet"
import GLOBE from "./shared/components/globe"
import MAP from "./shared/components/globe-2"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
import "../../static/assets/scss/pages/page.scss"

const routes = require("../types/routes")

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>
            Contingent: Proactively predict, manage and monitor third-party and
            supplier risk
          </title>
          <link rel="canonical" href={`${process.env.BASE_URL}`} />
          <meta
            name="title"
            content="Contingent: Proactive supplier and third-party risk management and monitoring"
          />
          <meta
            name="description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.BASE_URL}/`} />
          <meta
            property="og:title"
            content="Contingent: Proactive supplier and third-party risk management and monitorin"
          />
          <meta
            property="og:description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`${process.env.BASE_URL}/`} />
          <meta
            property="twitter:title"
            content="Contingent: Proactive supplier and third-party risk management and monitorin"
          />
          <meta
            property="twitter:description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"homepage"}>
          <div className={"container"}>
            <div className={"background"}>
              <GLOBE />
            </div>
          </div>
          <div className={"body"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column flex-small"}>
                  <h1 className={"title"}>
                    The Supplier Insight Platform Your Whole Business Will Love!
                  </h1>
                  <p className={"description"}>
                    The right supplier insights, at the right time, to make the right strategic choices, take action and collaborate.
                  </p>
                  <SignUpFormComponent />
                </div>
                <div className={"column pointer-handler"}></div>
              </div>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"logos-container"}>
                    <div className={"group"}>
                      <p className={"text"}>JOIN OTHER LEADING ORGANISATIONS</p>
                      <div className={"logos"}>
                        <img src={"/assets/images/logos/ukgovblack.svg"} />
                        <img src={"/assets/images/logos/monzo-black.svg"} />
                        <img src={"/assets/images/logos/seagate-black.svg"} />
                      </div>
                    </div>
                    <div className={"group hide"}>
                      <p className={"text"}>
                        ENSURE REGULATORY COMPLIANCE & BEST PRACTICE
                      </p>
                      <div className={"logos"}>
                        <img src={"/assets/images/logos/bci-trans.svg"} />
                        <img
                          src={"/assets/images/logos/pra-transparent.svg"}
                          className={"bigger"}
                        />
                        <img src={"/assets/images/logos/cips-trans.svg"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"section-container"}>
          <div className={"section-social-proof"}>
            <div className={"text"}>
              Real-time data on large and small suppliers such as:
            </div>
            <div className={"logos"}>
              <img
                src={"/assets/images/logos/shell-energy.svg"}
                className={"logo"}
              />
              <img src={"/assets/images/logos/gsk.svg"} className={"logo"} />
              <img src={"/assets/images/logos/boeing.svg"} className={"logo"} />
              <img src={"/assets/images/logos/toyota.svg"} className={"logo"} />
              <img src={"/assets/images/logos/pfizer.svg"} className={"logo"} />
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <div className={"content"}>
                    <p className={"tag"}>Global due-diligence</p>
                    <h2 className={"title"}>
                      Achieve complete visibility of your supply chain &
                      third-parties
                    </h2>
                    <p className={"description"}>
                      Streamline global due-diligence by importing your existing
                      database or seamlessly searching across millions of
                      companies for verified and compliant ownership, financial
                      data, certifications, and much more. Helping your team
                      understand the risks across your entire supply chain or
                      third-party network, whilst complying with regulations.
                    </p>
                    <SignUpFormComponent mode={"light"} />
                  </div>
                  <object
                    data={"/assets/images/graphics/contingent-1.svg"}
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column content-center-container"}>
                  <p className={"tag"}>Advanced Monitoring</p>
                  <h2 className={"title"}>Deploy proactive monitoring</h2>
                  <p className={"description"}>
                    Ease the burden with real-time and relevant alerts any time
                    your supplier or third-party is at risk. Surface relevant
                    data by selecting tolerance criteria, disruption events or
                    metrics you want to monitor, and receive these alerts in a
                    channel of your choice.
                    <br />
                    <br />
                    Free your team up to stop reacting and start planning.
                  </p>
                  <div className={"world--container"}>
                    <object
                      data={"/assets/images/graphics/monitoring.svg"}
                      className={"card"}
                    ></object>
                    <MAP className={""}></MAP>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <object
                    data={"/assets/images/graphics/procurement-4.svg"}
                    className={"card"}
                  ></object>
                  <div className={"content"}>
                    <p className={"tag"}>Insights</p>
                    <h2 className={"title"}>Improve your supply chain</h2>
                    <p className={"description"}>
                      Uncover efficiencies and savings with deep insights and
                      reporting at your fingertips, making the path to digital
                      transformation fast and cost-effective. Whilst improving
                      compliance by following the exact process that meets your
                      standards.
                    </p>
                    <SignUpFormComponent mode={"light"} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column centered reversed"}>
                  <div className={"content"}>
                    <p className={"tag"}>Automation</p>
                    <h2 className={"title"}>Automate your processes</h2>
                    <p className={"description"}>
                      By automating highly manual tasks, your teams are free to
                      spend time on work with better returns. A simple and
                      intuitive platform gives your employees more time — in
                      less time.
                    </p>
                    <SignUpFormComponent mode={"light"} />
                  </div>
                  <object
                    data={"/assets/images/graphics/contingent-2.svg"}
                    className={"strip"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-social-proof"}>
            <div className={"text"}>
              Trusted worldwide by organisations of all shapes and sizes
            </div>
            <div className={"logos"}>
              <img
                src={"/assets/images/logos/ukgovblack.svg"}
                className={"logo"}
              />
              <img
                src={"/assets/images/logos/monzo-black.svg"}
                className={"logo"}
              />
              <img
                src={"/assets/images/logos/healthcare.svg"}
                className={"logo"}
              />
              <img src={"/assets/images/logos/vyne.svg"} className={"logo"} />
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
